import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { rem, Text } from "@mantine/core";
import { useListState } from "@mantine/hooks";
import clsx from "clsx";
import { GripVertical } from "lucide-react";
import classes from "./DND.module.css";

const DND = ({ data }: { data: { label: string; value: string }[] }) => {
  const [state, handlers] = useListState(data);

  const items = state.map((item, index) => (
    <Draggable key={item.value} index={index} draggableId={item.value}>
      {(provided, snapshot) => (
        <div
          className={clsx(classes.item, { [classes.itemDragging]: snapshot.isDragging })}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div {...provided.dragHandleProps} className={classes.dragHandle}>
            <GripVertical style={{ width: rem(18), height: rem(18) }} />
          </div>
          <div>
            <Text>{item.label}</Text>
            <Text c="dimmed" size="sm">
              Position: {index + 1}
            </Text>
          </div>
        </div>
      )}
    </Draggable>
  ));

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => handlers.reorder({ from: source.index, to: destination?.index || 0 })}
    >
      <Droppable droppableId="dnd-list" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { DND };
