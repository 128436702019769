import { notifications } from "@mantine/notifications";

export const success = ({ title = "Success!", message = "" }) => {
  notifications.show({
    id: "success",
    withCloseButton: true,
    autoClose: 3000,
    title: title,
    message: message,
    loading: false,
  });
};
