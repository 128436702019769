import { PropsWithChildren, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

// Constants
import { CLIENT_ROUTES } from "@constants/";

// Hooks
import { useLogout } from "@hooks/";

type ProtectedRouteProps = PropsWithChildren & { isAuthenticated: boolean };

function ProtectedRoute({ isAuthenticated }: ProtectedRouteProps) {
  const navigate = useNavigate();
  const { logoutUser } = useLogout();

  useEffect(() => {
    if (isAuthenticated === false) {
      logoutUser();
      navigate(CLIENT_ROUTES.login);
    }
  }, [navigate, isAuthenticated, logoutUser]);

  return <Outlet />;
}

export { ProtectedRoute };
