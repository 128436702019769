import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

// Enums
import { EFilters } from "@enums/";

// Type
import type { TSearch } from "@_types/";

// API
import {
  getAllRequirementFolders,
  getAllRequirementsFromFolder,
  getOneRequirement,
  getRequirementsNoPagination,
} from "@api/";

// Constants
import {
  KEY_GET_ALL_REQUIREMENTS_FOLDERS,
  KEY_REQUIREMENTS_GET_ALL,
  KEY_REQUIREMENTS_GET_ALL_NO_PAGINATION,
} from "@constants/";
import { useParams } from "react-router-dom";

export const useGetRequirementFolders = ({ search, page, per_page }: TSearch) => {
  const foldersPayload = useMemo(() => {
    return {
      filters: {
        [EFilters.SEARCH]: search,
        [EFilters.PAGE]: page,
        [EFilters.PER_PAGE]: per_page,
      },
    };
  }, [page, per_page, search]);

  const {
    data: folders,
    isLoading: isFoldersLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_GET_ALL_REQUIREMENTS_FOLDERS, foldersPayload],
    queryFn: () => getAllRequirementFolders(foldersPayload),
    initialData: [],
  });

  return { folders, isLoading: isFoldersLoading || isFetching };
};

export const useGetAllRequirements = ({ search, page, per_page }: TSearch) => {
  const { folderId } = useParams();

  const benefitPayload = useMemo(() => {
    return {
      folderId: folderId,
      filters: {
        [EFilters.SEARCH]: search,
        [EFilters.PAGE]: page,
        [EFilters.PER_PAGE]: per_page,
      },
    };
  }, [page, per_page, search, folderId]);

  const {
    data: requirements,
    isLoading: isRequirementLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_REQUIREMENTS_GET_ALL, benefitPayload],
    queryFn: () => getAllRequirementsFromFolder(benefitPayload),
    initialData: [],
    enabled: !!folderId,
  });

  return { requirements, isLoading: isRequirementLoading || isFetching };
};

export const useGetRequirementsNoPagination = () => {
  const {
    data: requirements,
    isLoading: isRequirementLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_REQUIREMENTS_GET_ALL_NO_PAGINATION], // Ovo nam je kljuc preko koga kasnije mozemo da cachiramo
    queryFn: () => getRequirementsNoPagination(), // ovo nam je funkcija koja vraca neki response
    initialData: [], // Ovde ide bilo koji data koji tebi odgovara da bude inicijalni
    enabled: true,
  });

  return { requirements, isRequirementDropdownLoading: isRequirementLoading || isFetching };
};

export const useGetOneRequirement = ({ id }: { id: string | undefined }) => {
  const {
    data: requirement,
    isLoading: isRequirementLoading,
    isFetching,
  } = useQuery({
    queryKey: [KEY_REQUIREMENTS_GET_ALL, id],
    queryFn: () => getOneRequirement(id!),
    initialData: [],
    enabled: !!id,
  });

  return { requirement, isLoading: isRequirementLoading || isFetching };
};
