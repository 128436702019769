import type { TFolderFilter } from "@_types/";

// Utils
import { api, removeEmptyFromObject } from "@utils/";

// Constants
import { API_ROUTES } from "@constants/";

// Enums
import { EEntity } from "@enums/";

const getAllRequirementFolders = async ({ filters }: { filters?: TFolderFilter[] | TFolderFilter }) => {
  const data = removeEmptyFromObject({
    folder_type: EEntity.REQUIREMENTS,
    ...filters,
  });

  const response = await api.get(API_ROUTES.folders, data);
  return response?.data;
};

const getAllRequirementsFromFolder = async ({
  folderId,
  filters,
}: {
  folderId: string | undefined;
  filters?: TFolderFilter[] | TFolderFilter;
}) => {
  const data = removeEmptyFromObject({
    folder_id: folderId,
    ...filters,
  });

  const response = await api.get(API_ROUTES.requirements, data);
  return response?.data;
};

const getRequirementsNoPagination = async () => {
  const response = await api.get(`${API_ROUTES.requirements}/all`, {});
  return response?.data;
};

const getOneRequirement = async (id: string) => {
  const response = await api.get(`${API_ROUTES.requirements}/${id}`, {});
  return response?.data;
};

const createRequirement = async (data: unknown) => {
  const response = await api.post(API_ROUTES.requirements, {}, data);
  return response?.data;
};

const updateRequirement = async (id: string, data: unknown) => {
  const response = await api.patch(`${API_ROUTES.requirements}/${id}`, {}, data);
  return response?.data;
};

export {
  getAllRequirementFolders,
  getOneRequirement,
  updateRequirement,
  getAllRequirementsFromFolder,
  createRequirement,
  getRequirementsNoPagination,
};
