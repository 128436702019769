// MARK: Imports
import { TextInput } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

// Components
import { CustomTable, Filters, PageHeader, TableSkeleton } from "@components/";

// Requests
import { useGetAllRequirements } from "@api/";

// Constants
import {
  BENEFITS_TABLE_HEADER,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DEBOUNCE_TIME,
  DEFAULT_PER_PAGE,
  DEFAULT_TOTAL,
  DEFAULT_TOTAL_PAGES,
} from "@constants/";

// Enums
import { EEntity, EFilters, EPagination } from "@enums/";

const AllRequirements = () => {
  // MARK: State
  const [search, setSearch] = useDebouncedState("", DEFAULT_DEBOUNCE_TIME);
  const [paginator, setPaginator] = useState({
    [EPagination.PER_PAGE]: DEFAULT_PER_PAGE,
    [EPagination.CURRENT_PAGE]: DEFAULT_CURRENT_PAGE,
    [EPagination.TOTAL]: DEFAULT_TOTAL,
    [EPagination.TOTAL_PAGES]: DEFAULT_TOTAL_PAGES,
  });

  const searchValue = useMemo(() => {
    return search;
  }, [search]);

  // MARK: Functions
  const { requirements, isLoading } = useGetAllRequirements({
    [EFilters.SEARCH]: searchValue,
    [EFilters.PAGE]: paginator[EPagination.CURRENT_PAGE],
    [EFilters.PER_PAGE]: paginator[EPagination.PER_PAGE],
  });

  const onPageChange = (newPage: number) => {
    setPaginator((prev) => {
      return { ...prev, [EPagination.CURRENT_PAGE]: newPage };
    });
  };

  const resetFilters = useCallback(() => {
    setSearch("");
  }, [setSearch]);

  // MARK: Effects
  // Set initial pagination from backend
  useEffect(() => {
    if (!requirements || requirements?.length === 0) return;
    setPaginator(requirements?.pagination);
  }, [requirements]);

  // MARK: Renders
  if (isLoading) return <TableSkeleton />;

  return (
    <section>
      <PageHeader showBackBtn showCreateBtn title="Requirements" entity={EEntity.REQUIREMENTS} />

      <Filters onReset={resetFilters}>
        <form>
          <TextInput
            defaultValue={search}
            onChange={(event) => setSearch(event?.currentTarget?.value)}
            placeholder="Search by requirement name..."
            variant="unstyled"
          />
        </form>
      </Filters>

      <div className="w-full">
        <CustomTable
          hasFolderStructure
          columns={BENEFITS_TABLE_HEADER}
          entity={EEntity.REQUIREMENTS}
          handleDelete={() => {}}
          onPageChange={onPageChange}
          paginator={requirements.pagination}
          showDelete
          showEdit
          tableData={requirements.data}
        />
      </div>
    </section>
  );
};

export { AllRequirements };
