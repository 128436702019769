import { Button, PasswordInput, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

// Assets
import loginBg from "@images/loginBg.webp";

// Constants
import {
  CLIENT_ROUTES,
  EMAIL,
  LABEL_EMAIL,
  LABEL_PASSWORD,
  NOT_EMPTY,
  PLACEHOLDER_EMAIL,
  PLACEHOLDER_PASSWORD,
} from "@constants/";

// Components
import { Logo } from "@components/";

// Requests
import { loginRequest } from "@api/";

// Hooks
import { useAuth } from "@hooks/";

// Utils
import { error, success } from "@utils/";

// Interfaces
import { EActionAuthType } from "@interfaces/";

type TLogin = z.infer<typeof schema>;

enum ELoginFormFields {
  EMAIL = "email",
  PASSWORD = "password",
}

const schema = z.object({
  [ELoginFormFields.EMAIL]: z.string({ required_error: NOT_EMPTY }).email({ message: EMAIL }),
  // Temporary commented
  // password: z.string({ required_error: NOT_EMPTY }).regex(REGEX_PASSWORD, {
  //   message: PASSWORD,
  // }),
  [ELoginFormFields.PASSWORD]: z.string({ required_error: NOT_EMPTY }),
});

function Login() {
  const { dispatch } = useAuth();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    initialValues: {
      [ELoginFormFields.EMAIL]: "",
      [ELoginFormFields.PASSWORD]: "",
      honeypot: false,
    },
    validate: zodResolver(schema),
  });

  const onLogin = async (values: TLogin) => {
    setIsLoading(true);
    const { email, password } = values;

    const response = await loginRequest({
      [ELoginFormFields.EMAIL]: email,
      [ELoginFormFields.PASSWORD]: password,
    });

    navigate(CLIENT_ROUTES.root);

    if (response) {
      console.log("response?.data", response?.data);
      setIsLoading(false);
      success({
        title: "Logged in successfully. Welcome!",
      });

      dispatch({
        type: EActionAuthType.LOGIN,
        payload: response?.data,
      });

      navigate(CLIENT_ROUTES.root);
    } else {
      setIsLoading(false);
      error({ title: "Authentication failed." });
    }
  };

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="flex w-full items-center justify-center lg:w-2/5 xl:w-1/3">
        <article className="w-full px-lg lg:px-2xl">
          <div className="flex w-full items-center justify-center">
            <Logo disableLink size="text-4xl" />
          </div>
          <form
            className="mt-md flex h-full w-full flex-col gap-y-md"
            onSubmit={form.onSubmit((values) => {
              if (values.honeypot) return;
              onLogin(values);
            })}
          >
            <TextInput
              autoComplete="email"
              label={LABEL_EMAIL}
              placeholder={PLACEHOLDER_EMAIL}
              {...form.getInputProps(ELoginFormFields.EMAIL)}
            />
            <PasswordInput
              autoComplete="new-password"
              label={LABEL_PASSWORD}
              placeholder={PLACEHOLDER_PASSWORD}
              {...form.getInputProps(ELoginFormFields.PASSWORD)}
            />
            <Button loading={isLoading} type="submit">
              Login
            </Button>
          </form>
        </article>
      </div>
      <div className="relative hidden h-full lg:block lg:w-3/5 xl:w-2/3">
        <img src={loginBg} className="h-full w-auto object-cover" alt="auth image" />
      </div>
    </div>
  );
}

export { Login };
