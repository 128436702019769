import { notifications } from "@mantine/notifications";

export const info = ({ title = "Info!", message = "" }) => {
  notifications.show({
    id: "info",
    withCloseButton: true,
    autoClose: 3000,
    title: title,
    message: message ?? null,
    loading: false,
  });
};
